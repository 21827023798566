.alert-popup-modal-img {
  margin-bottom: 28px;
  img {
    width: 128px;
  }
}
.alert-popup-modal-title {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--mainTextColor);
  margin-bottom: 8px;
}
.alert-popup-modal-content {
  font-size: 1.5rem;
  color: var(--mainTextColor);
  margin-bottom: 20px;
  line-height: 2.1rem;
  .primary {
    color: var(--primaryColor);
  }
  .bold {
    font-weight: bold;
  }
  .count {
    margin: 0 2px;
  }
}
.alert-popup-modal-footer {
  padding-bottom: 28px;
  .alert-popup-modal-footer__button {
    font-size: 1.6rem;
    font-weight: bold;
    border: none;
    color: var(--primaryColor);
    background-color: transparent;
  }
}
