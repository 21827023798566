.fee-details-display {
  color: var(--mainTextColor);
  &__subtotal {
    font-size: 1.5rem;
    padding: 15px 0;
    border-top: 1px solid #eaeaea;
  }
  &__subtotal-item {
    display: flex;
    justify-content: space-between;
    +.fee-details-display__subtotal-item {
      margin-top: 10px;
    }
  }
  &__price {
    font-weight: bold;
  }
  &__total-item {
    display: flex;
    justify-content: space-between;
  }
  &__sum-label {
    font-size: 1.7rem;
    font-weight: bold;
  }
  &__sum-price {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--systemMagenta);
  }
  &__total {
    padding: 15px 0;
    border-top: 1px dashed #eaeaea;
  }
}