.footer-confirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin: 0 20px;
  position: relative;
  &__wrap {
    background: var(--systemWhite);
    z-index: 2;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.06);
  }
  + .footer-confirm {
    border-top: 1px solid #eaeaea;
  }
  &__fare__price {
    display: flex;
    align-items: center;
    .price {
      margin-left: 0.8rem;
    }
    .no-price {
      font-size: 21px;
    }
    .has-price {
      color: var(--systemMagenta);
      font-size: 2.1rem;
      font-weight: bold;
    }
    .loading {
      margin-left: 10px;
    }
  }
  &__fare-detail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
    font-size: 1.3rem;
    color: #999999;
    line-height: 2.4rem;
    i {
      font-size: 2.4rem;
      margin-left: 2px;
    }
  }
  &__fare-text {
    font-size: 1.3rem;
    color: #999999;
    margin-right: 4px;
  }
  &__origin-fare {
    font-size: 1.5rem;
    color: var(--mainTextColor);
    text-decoration: line-through;
  }
  &__toggle {
    display: flex;
    align-items: center;
    color: #999999;
    line-height: 2.4rem;
    span {
      font-size: 1.3rem;
    }
    &__icon {
      font-size: 2.4rem;
      color: #999999;
    }
    &__toggle {
      color: #999999;
    }
    &--active {
      color: var(--primaryColor);
    }
  }
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    word-break: keep-all;
    color: var(--mainTextColor);
  }
}
