.logList-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logList-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background: linear-gradient(145deg, #e1e1e1, #ffffff);
    box-shadow: 20px 20px 60px #d5d5d5, -20px -20px 60px #ffffff;
  }

  & a {
    text-decoration: none;
  }
}
