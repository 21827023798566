.page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.autocomplete.active {
  height: 0;
  min-height: 0;
  padding: 0 20px;
}

// .edit {
//   // position: absolute;
//   // top: 0;
//   // display: flex;
//   // transition: .5s;
//   // flex-direction: column;
//   // justify-content: center;
//   // width: 100%;
//   // min-height: 126px;
//   // padding: 10px 20px;
//   // overflow: auto;
//   // outline: none;
//   // // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
//   // z-index: 4;
//   background: var(--systemWhite);

//   & > div {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     position: relative;
//     flex: 1;
//     min-height: 63px;
//   }

//   & > div > span {
//     font-size: 1.4rem;
//     color: #999;
//     text-align: left;
//     margin-bottom: 5px;
//   }

//   & > div:nth-child(1) > input {
//     margin-bottom: 20px;
//   }
//   // 外層 Input 元件
//   & > div > div {
//     display: flex;
//     position: relative;
//     min-height: 44px;
//     margin-bottom: 5px;
//     width: 100%;
//   }
//   // 真輸入框
//   & > div > div > input {
//     width: 100%;
//     border: none;
//     font-size: 1.5rem;
//     line-height: normal;
//     padding: 10px 12px;
//     border-radius: 5px;
//   }
//   // 偽輸入框
//   & > div > div > div {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     pointer-events: none;
//     min-height: 44px;
//     border: 1px solid #999;
//     border-radius: 5px;
//     top: 0;
//     left: 0;
//   }
//   // 刪除鍵
//   & > div > div > i {
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     background: var(--systemWhite);
//     width: 20px;
//     display: flex;
//     opacity: 1;
//     justify-content: center;
//     align-items: center;
//     font-size: 1.6rem;
//   }
//   // 輸入框縮短
//   & > div > div > input.active {
//     width: calc(100% - 30px);
//   }
//   // 偽輸入框
//   & > div > div > input.active + div {
//     border: 1px solid var(--primaryColor);
//   }
// }

.prefer_inputbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
  min-height: 63px;

  & > span {
    font-size: 1.4rem;
    color: #999;
    text-align: left;
    margin-bottom: 5px;
  }

  &__input {
    width: 100%;
    display: flex;
    position: relative;
    min-height: 44px;
    margin-bottom: 5px;
    padding: 0 5px;
    align-items: center;
    // 真輸入框
    input {
      width: 100%;
      flex: 1;
      border: none;
      font-size: 1.5rem;
      line-height: normal;
      padding: 10px 12px;
      border-radius: 5px;
    }
    // 偽輸入框
    input + div {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      min-height: 44px;
      border: 1px solid #999;
      border-radius: 5px;
      top: 0;
      left: 0;
    }
    // 偽輸入框
    input.active + div {
      border: 1px solid var(--primaryColor);
    }

    .icon-clear {
      width: 30px;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &:active {
        opacity: 0.7;
      }
    }

    p {
      width: 30px;
      line-height: 20px;
      font-size: 1.4rem;
      color: var(--primaryColor);
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      &:active {
        opacity: 0.7;
      }
    }
  }
}

.autocomplete {
  display: flex;
  transition: 0.5s;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  min-height: 126px;
  padding: 10px 20px;
  overflow: auto;
  outline: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;

  & > i:nth-child(3) {
    font-size: 1rem;
    position: absolute;
    width: 40px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &__searchbar {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    width: 100%;
  }

  &__searchbar:nth-child(1) &__searchbar__icon {
    font-size: 1.66rem;
  }

  &__searchbar__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.8rem;
    background: white;
  }

  &__searchbar__input {
    margin-left: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-height: 44px;
    border-radius: 5px;
    border: 1px solid #949494;
    overflow: hidden;
    padding: 0 5px;
    color: #cdcdcd;

    & i {
      font-size: 2rem;
      width: 3rem;
      // min-width: 4rem;
    }

    & p {
      line-height: 20px;
      font-size: 1.4rem;
      color: var(--primaryColor);
      transform: translateY(-1px);
    }

    & input {
      flex: 1;
      border: none;
      background: transparent;
      font-size: 1.5rem;
      line-height: normal;
    }

    & input:focus {
      outline: none;
    }

    & span {
      color: var(--primaryColor);
      font-weight: bold;
      text-align: left;
      font-size: 1.6rem;
    }
  }

  &__searchbar__input.active {
    box-shadow: none;
    outline: none;
    color: var(--mainTextColor);
    border: 1px solid var(--primaryColor);
    height: 30px;

    & input {
      font-size: 1.5rem;
    }

    & span {
      display: none;
    }
  }

  &__searchbar__input.error {
    border: 1px solid #f05151;
  }
}

.pageSwitch {
  position: relative;
  width: 100%;
  overflow: hidden;
  flex: 1 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mapContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  transition: 0.5s;
}

#map {
  position: absolute;
  width: 100vw;
  height: 100%;
}

.arrow {
  width: 50px;
  height: 50px;
  font-size: 3rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1;
  color: #111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-alert {
  letter-spacing: 2px;
  position: absolute;
  font-size: 1rem;
  top: -30px;
  width: 80px;
  height: 40px;
  color: black;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: rgba(255, 255, 255, 0.8) transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.arrowMove {
  animation: animate1 1s ease-in-out infinite;
}

@keyframes animate1 {
  0% {
    filter: drop-shadow(1px 4px 6px rgba(#00a6ff, 0.5));
    transform: translate(-50%, -112%);
  }

  50% {
    filter: drop-shadow(1px 4px 6px rgba(#00a6ff, 0.5));
    transform: translate(-50%, -102%);
  }

  100% {
    filter: drop-shadow(1px 4px 6px rgba(#00a6ff, 0.5));
    transform: translate(-50%, -112%);
  }
}

.address__list {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 5px 0 5px 10px;
  max-width: calc(100% - 60px);
}

.subAddress {
  flex: 1;
  border: none;
  text-align: left;
  background: white;
}

.predictResult {
  position: relative;
  width: 100%;
  overflow: hidden;
  // -webkit-overflow-scrolling: touch; // safari
  flex: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 1;
  transition: 0.5s;
  transform: translateY(110%);
  background-color: white;

  & > div:nth-child(2) {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    -webkit-overflow-scrolling: touch; // safari
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
  }

  &.active {
    transform: translateY(0%);
  }

  &.inActive {
    transition-delay: 0.3s;
  }

  &__headerItem {
    background: linear-gradient(0deg, transparent 4px, white 0);
    display: flex;
    flex: 1;
    min-height: 60px;
    justify-content: center;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    padding-right: 1px;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      filter: blur(1px);
      width: 1px;
      right: 0;
      background: rgba(0, 0, 0, 0.2);
      height: 25px;
      top: 50%;
      transform: translate(0px, -50%);
    }

    &:nth-child(3):before {
      display: none;
    }

    & .headerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      font-size: 3rem;
      color: #999999;
      margin: 0 5px 0 0;
      transition: 0.05s;
      transition-timing-function: linear;
    }

    & > span {
      display: flex;
      align-items: center;
      max-width: 60px;
      text-align: center;
      color: #999;
      font-size: 1.6rem;
      transition: 0.05s;
      transition-timing-function: linear;
    }
  }

  &__headerItem.active {
    // box-shadow: inset 0 -4px var(--primaryColor);

    & .headerIcon {
      color: var(--primaryColor);
    }

    & > span {
      color: var(--primaryColor);
    }
  }

  &__headerItem:nth-child(1).active ~ .predictResult__slider {
    left: 0;
  }

  &__headerItem:nth-child(2).active ~ .predictResult__slider {
    left: 33.3%;
  }

  &__headerItem:nth-child(3).active ~ .predictResult__slider {
    left: 66.6%;
  }

  &__slider {
    position: absolute;
    bottom: 0;
    left: -33.3%;
    width: 33.3%;
    height: 4px;
    background: var(--primaryColor);
    transition: 0.05s;
    transition-timing-function: ease-in-out;
  }

  &__itemContainer {
    min-height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__itemContainer .editActive {
    font-size: 1.6rem;
    position: relative;
    overflow: hidden;
    min-height: 44px;
    width: 100%;
    background: var(--primaryColor);
    color: var(--systemWhite);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 10px 20px;
  }

  & > &__itemContainer:nth-child(1) {
    box-shadow: -15px -15px 15px rgba(255, 255, 255, 0), 15px 15px 15px rgba(0, 0, 0, 0),
      inset 0px 4px 5px rgba(255, 255, 255, 0.8), inset 0px -4px 5px rgba(0, 0, 0, 0.05);
  }

  &__isPrefer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    width: 50px;
    height: 100%;
    font-size: 3rem;
    color: #efefef;
    z-index: 1;

    &.active {
      box-shadow: none;
      color: var(--primaryColor);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #efefef;
    font-size: 2rem;
    width: 35px;
    height: 35px;
    min-width: 35px;
    color: #999;
    margin: 0 20px 0 20px;

    & .icon-heart-2 {
      transform: translateY(1px);
    }

    &.active {
      box-shadow: none;
      color: var(--primaryColor);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // padding: .5rem .5rem .5rem 0;
    height: 50px;
    max-width: calc(100% - 125px);
    // pointer-events: auto;
    // cursor: pointer;
    flex: 1;
    color: var(--mainTextColor);
    font-size: 1.6rem;
    text-align: center;
    // margin: .5rem 0;
  }
}

.secondaryText {
  font-size: 1.5rem;
  color: #999;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
}

.mainText {
  color: var(--mainTextColor);
  text-align: start;
  width: 100%;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
}

.gpsBtn {
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: var(--systemWhite);
  right: 20px;
  bottom: 80px;
  overflow: hidden;
  font-size: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 5px 1px rgba(1, 1, 1, 0.4);
}

.okBtn {
  border: none;
  min-height: 44px;
  position: absolute;
  bottom: 10px;
  left: 20px;
  right: 20px;
  border-radius: 5px;
  background: var(--primaryColor);
  color: var(--systemWhite);
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:disabled {
    filter: opacity(0.6);
  }

  & span {
    font-size: 1.6rem;
    text-align: center;
  }
}
