.select-ticket-modal {
  display: flex;
  flex-direction: column;
  height: calc(var(--app-height) - 30px); // 30px是modal header的高度
  color: var(--mainTextColor);
  .select-ticket-modal__title-wrap,
  .select-ticket-modal__footer {
    flex-shrink: 0; /* prevent shrinking */
  }
  &__title-wrap {
    background-color: var(--systemWhite);
    z-index: 2;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  }
  &__title {
    font-size: 2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
    font-weight: bold;
  }
  &__ticket-wrap {
    transition: max-height 0.4s ease-in-out;
    max-height: 1000px;
    .ticket-template {
      + .ticket-template {
        margin-top: 8px;
      }
    }
    &--collapse {
      max-height: 128px;
      overflow: hidden;
    }
  }
  &__info {
    padding: 15px 16px;
    text-align: left;
    &__title {
      font-size: 1.7rem;
      font-weight: bold;
      padding-bottom: 11px;
      border-bottom: 1px solid #d2d2d2;
    }
  }
  &__discount-display {
    padding-top: 11px;
    color: var(--mainTextColor);
    font-size: 1.6rem;
    line-height: 2.4rem;
    span {
      line-height: 2rem;
    }
    &__price {
      font-size: 2rem;
      color: var(--systemMagenta);
      font-weight: bold;
    }
  }
  &__content {
    background-color: #efeff4;
    overflow-y: auto;
    flex-grow: 1;
    padding: 14px 0 14px 12px;
  }
  &__collapse-btn {
    margin: 10px 0 0 4px;
    font-size: 1.6rem;
    color: var(--mainTextColor);
    text-align: left;
    display: flex;
    align-items: center;
    i {
      font-size: 2.4rem;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    position: relative;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.06);
  }
}

.ticket-template {
  position: relative;
  display: flex;
  width: 100%;
  height: 120px;
  padding: 0 0 0 5px;
  box-sizing: border-box;
  &.ticket-template--active {
    .ticket-template__left .ticket-template__wrap--middle .ticket-template__title {
      color: var(--primaryColor);
    }
    .ticket-template__right .ticket-template__wrap--middle i {
      color: var(--primaryColor);
    }
  }
  &.ticket-template--fake {
    .ticket-template__left {
      .ticket-template__wrap--middle {
        .ticket-template__title {
          padding: 0;
          line-height: 15px;
          width: 60%;
          margin: 5px 0;
        }
        .ticket-template__desc {
          width: 80%;
          padding: 0;
          margin-bottom: 8px;
        }
        .ticket-template__date-time {
          width: 50%;
          padding: 0;
        }
      }
    }
    .ticket-template__line {
      background: #f6f6f6;
      background: linear-gradient(to right, #efeff1 8%, #f9f9f9 18%, #efeff1 33%);
      background-size: 800px 104px;
      -webkit-animation: placeHolderShimmer 2s infinite;
      animation: placeHolderShimmer 2s infinite;
    }
  }
  &.ticket-template--invalid {
    pointer-events: none;
    .ticket-template__left {
      .ticket-template__wrap {
        &--front {
          background-image: url('../assets/tickets/coupon_fff50_l.png');
        }
        &--middle {
          background-image: url('../assets/tickets/coupon_fff50_bg.png');
          .ticket-template__desc,
          .ticket-template__title,
          .ticket-template__date-time {
            color: #999999;
          }
        }
        &--end {
          background-image: url('../assets/tickets/coupon_fff50_c.png');
        }
      }
    }
    .ticket-template__right {
      .ticket-template__wrap {
        &--middle {
          background-image: url('../assets/tickets/coupon_fff50_bg.png');
        }
      }
    }
  }
  .ticket-template__left {
    position: relative;
    width: 75%;
    height: 100%;
    display: flex;
    .ticket-template__wrap {
      &--front {
        width: 15px;
        height: 100%;
        background-image: url('../assets/tickets/coupon_fff_l.png');
        background-size: cover;
      }
      &--middle {
        position: relative;
        width: calc(100% - 35px);
        height: 100%;
        background-image: url('../assets/tickets/coupon_fff_bg.png');
        background-repeat: repeat;
        background-size: contain;
        box-sizing: border-box;
        padding: 14px 40px 14px 16px;
        overflow: hidden;
        text-align: left;
        p {
          display: block;
          width: 100%;
          line-height: 1.8rem;
          box-sizing: border-box;
          &.ticket-template__title {
            font-weight: bold;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--mainTextColor);
            font-size: 1.6rem;
            margin-bottom: 8px;
          }
          &.ticket-template__desc {
            color: #999999;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-word;
            font-size: 1.3rem;
          }
          &.ticket-template__date-time {
            color: #999999;
            position: absolute;
            bottom: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 1.3rem;
          }
        }
      }
      &--end {
        width: 21px;
        height: 100%;
        background-image: url('../assets/tickets/coupon_fff_c.png');
        background-size: cover;
      }
    }
  }
  .ticket-template__right {
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    .ticket-template__wrap--middle {
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-image: url('../assets/tickets/coupon_fff_bg.png');
      background-repeat: repeat;
      background-size: contain;
      i {
        font-size: 21px;
        cursor: pointer;
        &.icon-checkbox-select-empty {
          color: #eaeaea;
        }
        &.icon-checkbox-select {
          color: var(--primaryColor);
        }
      }
    }
  }
  .ticket-template__disable-mark {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-11deg);
    -ms-transform: translate(-50%, -50%) rotate(-11deg);
    transform: translate(-50%, -50%) rotate(-11deg);
    color: #fd773d;
    font-weight: bold;
    border: solid 2px;
    padding: 3px;
    border-radius: 3px;
    z-index: 1;
  }
  .ticket-template__radio {
    min-width: 70px;
    display: flex;
    & input[type='radio'] {
      position: relative;
      width: 24px;
      height: 24px;
      border: 2px solid #eaeaea;
      border-radius: 50%;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: opacity 0.2s;

      &::before {
        position: absolute;
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0px;
        height: 0px;
        background-color: var(--primaryColor);
        border-radius: 50%;
        transition: width 0.2s, height 0.2s;
      }
      &:checked {
        border-color: var(--primaryColor);
        background: var(--systemWhite);
        &::before {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}
/* fakeItem loading */
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
