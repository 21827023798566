* {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: 'Noto Sans TC','Arial','Microsoft YaHei', sans-serif;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

*:not(input):not(textarea) {
    -webkit-user-select: none; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

span, p {
    user-select: none;
}
ol, ul {
    list-style: none;
}
h1 {
  font-weight: 500;
}
html {
	  font-size: 62.5%;
}
html,body {
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
    color: #999;
}

textarea::placeholder {
    color: #999;
}

button, input, textarea, select, [role="radio"]:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #111;
}

::-webkit-scrollbar-track
{
  background-color: transparent;
}

::-webkit-scrollbar
{
  width: 2px;
  height: 2px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

:root {
  --app-height: 100vh;
  --primaryColor: #15beae;
  --systemOrange: #fd773d;
  --systemRed: #fd2d55;
  --systemWhite: #ffffff;
  --mainTextColor: #484848;
  --systemMagenta: #db2959;
}