.form {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s;
  // margin-top: 12.6rem;
  height: 100%;
  z-index: 2;
  background: #fefefe;
  box-shadow: inset 0px 3px 3px rgba(1, 1, 1, 0.1);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.form.active {
  padding: 0;
  height: 0;
  overflow: hidden;
  // background: transparent;
  // display: none;
}

.form.inActive {
  transition-delay: 0.2s;
}

.formContainer {
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  & span {
    text-align: left;
    color: var(--mainTextColor);
    margin: 0.5rem 0;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 18px;
    &.formContainer__subtitle {
      margin-left: 8px;
      font-size: 13px;
      color: #999999;
      font-weight: 400;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__box {
    padding: 10px 12px;
    min-height: 44px;
    border-radius: 5px;
    border: 1px solid #999;
    display: flex;
    position: relative;
    transition: 0.5s;

    &.active {
      border: 1px solid #f05151;
    }

    & h3 {
      color: #999;
      position: absolute;
      right: 0;
      top: -22px;

      & span {
        font-size: 1.4rem;
        color: #f05151;
      }
    }

    & input {
      flex: 1 1;
      border: none;
      font-size: 1.5rem;
      line-height: normal;
      background: transparent;
    }

    &__alert {
      width: 80px;
      color: #f05151;
      display: flex;
      align-items: center;
      font-size: 1.3rem;
    }
  }

  &__sp {
    flex: 1;
    display: flex;

    &__checkbox {
      min-width: 70px;
      // height: 3rem;
      display: flex;
      margin-right: 30px;

      & input[type='checkbox'] {
        // transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        position: relative;
        width: 20px;
        height: 20px;
        color: #999;
        border: 1px solid #999;
        border-radius: 50%;
        appearance: none;
        outline: 0;
        cursor: pointer;

        &::before {
          position: absolute;
          content: '';
          display: block;
          top: 5px;
          left: 8px;
          width: 4px;
          height: 9px;
          border-style: solid;
          border-color: var(--systemWhite);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) translate(-3px, 0px);
          opacity: 0;
        }

        &:checked {
          color: var(--systemWhite);
          border-color: var(--primaryColor);
          background: var(--primaryColor);

          &::before {
            opacity: 1;
          }
        }
      }

      & label {
        flex: 1;
        min-width: 50px;
        position: relative;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0 8px 0;
        user-select: none;
        color: var(--mainTextColor);
      }
    }
    &__radio {
      min-width: 70px;
      display: flex;
      margin-right: 30px;
      & input[type='radio'] {
        position: relative;
        width: 20px;
        height: 20px;
        color: #999;
        border: 1px solid #999;
        border-radius: 50%;
        appearance: none;
        outline: 0;
        cursor: pointer;

        &::before {
          position: absolute;
          content: '';
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          background-color: var(--primaryColor);
          border-radius: 50%;
          opacity: 0;
        }

        &:checked {
          border-color: var(--primaryColor);
          background: var(--systemWhite);
          &::before {
            opacity: 1;
          }
        }
      }

      & label {
        flex: 1;
        min-width: 50px;
        position: relative;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0 8px 0;
        user-select: none;
        color: var(--mainTextColor);
      }
    }
    &--column {
      flex-direction: column;
      .formContainer__sp {
        &__radio {
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-right: 0;
          & label {
            flex: 0 0 auto;
            white-space: nowrap;
            width: auto;
          }
          &__btn {
            margin-left: auto;
            border: 0;
            background: none;
            display: flex;
            align-items: center;
            span {
              color: #999;
              font-size: 1.3rem;
              font-weight: 500;
            }
            i {
              font-size: 1.6rem;
              color: #999;
            }
          }
        }
      }
    }
  }

  &__textarea {
    // flex: 1;
    min-height: 78px;
    border-radius: 5px;
    border: 1px solid #999;
    display: flex;
    padding: 10px 12px;

    & textarea {
      flex: 1;
      font-size: 1.5rem;
      border: none;
      resize: none;
    }
  }
}

.formContainer.high-block {
  min-height: 120px;
  padding-bottom: 12px;
}
// 文新提醒
.formContainer.reminder > span {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.3;
  color: #999;
}

.form__body {
  position: relative;
  padding-top: 20px;
  // min-height: 320px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

@media (max-width: 330px) {
  .formContainer__sp__checkbox {
    margin-right: 10px;
  }

  .formContainer {
    padding-bottom: 10px;
  }
}
