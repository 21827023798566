.cancel-event-img {
  position: relative;
  width: 100%;
  height: 350px;

  .cancel-event-back {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 105px;
    border-radius: 50%;
    height: 127px;
    width: 240px;
    background: #ebf4ff;
  }

  > div {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cancel-event-hand {
    width: 33px;
    height: 24px;
    left: calc(50% + 40px);
    bottom: 205px;
    background-image: url('../assets/cancel/hand.png');
    transform: rotate(-15deg);
    transform-origin: top left;
    animation: handMove 1s linear infinite;
  }

  .cancel-event-dialogue {
    width: 140px;
    height: 50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 9px;
    top: 40px;
    background-image: url('../assets/cancel/dialogue.png');
    animation: dialogueMove 1s linear infinite;

    > span {
      font-size: 1.3rem;
    }
  }

  .cancel-event-front {
    width: 210px;
    height: 200px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    background-image: url('../assets/cancel/front.png');
  }
}

.cancel-event-tips {
  font-size: 1.6rem;
  text-align: center;
  margin: 12px 0;
  color: var(--mainTextColor);
  line-height: 2.2rem;
}

@keyframes dialogueMove {
  0%,
  100% {
    transform: translateX(-50%) translateY(-5px);
  }

  50% {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes handMove {
  0% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(-15deg);
  }
}
