.comment-modal {
  display: flex;
  flex-direction: column;
  color: var(--mainTextColor);
}

.comment-modal-body {
  display: flex;
  flex-direction: column;
}

.comment-modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2.4rem;
  text-align: center;
  color: var(--primaryColor);
  font-weight: bold;
}

.comment-modal-question {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2.4rem;
}

.comment-modal-rate {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  .rc-rate {
    font-size: 4.8rem;
    margin-bottom: 0.8rem;
    > *:focus {
      outline: none;
    }

    .rc-rate-star-half .rc-rate-star-first,
    .rc-rate-star-full .rc-rate-star-second {
      color: #fedc64;
    }
    .rc-rate-star-half:hover .rc-rate-star-first,
    .rc-rate-star-full:hover .rc-rate-star-second {
      color: #fce286;
    }
  }

  .rc-rate-star {
    margin-right: 0;
  }

  > span {
    transition: 0.3s;
    font-size: 1.5rem;
    color: var(--mainTextColor);
    opacity: 1;
    pointer-events: none;

    &.hide {
      transform: translateY(-2rem);
      opacity: 0;
    }
  }
}

.comment-modal-questionContent {
  overflow-y: scroll;
  max-height: calc(var(--app-height) - 278px - 24px);
  min-height: calc(var(--app-height) - 278px - 24px);
  transition: 0.3s;

  &.hide {
    opacity: 0;
    transform: translateY(-2rem);
    display: none;
  }
}

.comment-modal-question-fakeContent {
  overflow-y: scroll;
  max-height: calc(var(--app-height) - 60vh - 24px);
  min-height: calc(var(--app-height) - 60vh - 24px);
  transition: 0.3s;
  &.no-rating {
    max-height: calc(var(--app-height) - 40vh - 24px);
    min-height: calc(var(--app-height) - 40vh - 24px);
  }
}

.comment-modal-tagType {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
}

.comment-modal-tagList {
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.comment-modal-tag {
  margin: 0.4rem;
  padding: 0.6rem 1.2rem;
  background: #efeff4;
  border-radius: 1.7em;
  font-size: 1.5rem;
  transition: 0.3s;
}

.comment-modal-tag-active {
  background: var(--primaryColor);
  color: var(--systemWhite);
}

.comment-modal-more {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;

  .comment-modal-more-text-enable {
    > div {
      display: flex;
      position: relative;
      justify-content: center;
      margin-bottom: 1.6rem;
      margin-top: 2rem;

      > span:nth-child(1) {
        text-align: center;
        color: var(--mainTextColor);
        font-weight: 600;
        font-size: 1.7rem;
      }

      > span:nth-child(2) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: var(--primaryColor);
        font-size: 1.5rem;
      }
    }
    > p {
      width: 100%;
      border: none;
      font-size: 1.5rem;
      line-height: 2.5rem;
      max-width: 100%;
      white-space: pre-wrap;
      word-break: normal;
      word-wrap: break-word;
      text-align: left;
    }
  }

  .comment-modal-more-text {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primaryColor);
    > span {
      width: 1.8rem;
      height: 1.8rem;
      position: relative;

      &::after,
      &::before {
        position: absolute;
        content: '';
        width: 1rem;
        height: 0.2rem;
        background-color: #ccc;
        left: 50%;
      }

      &::after {
        top: 0.6rem;
        transform: rotate(45deg);
      }

      &::before {
        bottom: 0.4rem;
        transform: rotate(-45deg);
      }
    }
  }

  .comment-modal-more-title {
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1.6rem;
  }
}

.comment-modal-more-textarea {
  margin-top: 1rem;
  font-size: 1.5rem;
  padding: 0.8rem 1.2rem;
  border: 1px solid #eaeaea;
  border-radius: 0.8rem;
  min-height: 24rem;
  outline: none;
  resize: none;
}

.comment-modal-submit {
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  background-color: var(--primaryColor);
  border-radius: 4px;
  color: var(--systemWhite);
  font-size: 1.6rem;
  border: none;
}

.comment-modal-footer {
  display: flex;
  padding-bottom: 24px;
  margin-top: 30px;
}

// * comment animation Image
.comment-modal-image {
  position: absolute;
  top: 10rem;
  align-self: center;
  width: 300px;
  height: 300px;
  transition: 0.3s;
  pointer-events: none;

  @media (max-width: 374px) {
    transform: scale(0.85);
  }

  &.hide {
    transform: translateY(-2rem);
    opacity: 0;
    pointer-events: none;

    @media (max-width: 374px) {
      transform: translateY(-2rem) scale(0.85);
    }
  }

  .comment-modal--asset {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .comment-modal--back {
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    height: 56%;
    width: 100%;
    background: #ebf4ff;
  }
}

.comment-modal-welcomeImage {
  .comment-modal--starAlert {
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(56% + 1.4rem);
    width: 22.6%;
    height: 19.3%;
    background-image: url('../assets/comment/starInfo.png');
    animation: starAlertMove 1.6s linear infinite;
  }
  @keyframes starAlertMove {
    0% {
      transform: translateX(-50%) translateY(0px);
    }
    18.75%,
    31.25% {
      transform: translateX(-50%) translateY(4px);
    }
    67.75%,
    81.25% {
      transform: translateX(-50%) translateY(-3px);
    }
    100% {
      transform: translateX(-50%) translateY(0px);
    }
  }
  .comment-modal--car {
    width: 47%;
    height: 33%;
    right: -17px;
    bottom: 51px;
    background-image: url('../assets/comment/taxi.png');
    transition: 1s;
    transition-timing-function: ease-in-out;
  }
  .comment-modal--phone {
    width: 83%;
    height: 53%;
    left: -17px;
    bottom: 26px;
    background-image: url('../assets/comment/phone.png');
  }
}

.comment-modal-thankTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 40px 0 30px 0;
  white-space: pre-line;
  line-height: 3rem;
}
.comment-modal-thank {
  .comment-modal--screen {
    width: 32%;
    height: 59%;
    left: 48px;
    bottom: 73px;
    background-image: url('../assets/comment/screen.png');
  }

  .comment-modal--message1 {
    width: 24%;
    height: 22%;
    left: 81px;
    bottom: 169px;
    background-image: url('../assets/comment/message01.png');
  }
  .comment-modal--message2 {
    width: 24%;
    height: 24%;
    left: 39px;
    bottom: 106px;
    background-image: url('../assets/comment/message02.png');
  }

  .comment-modal--message3 {
    width: 24%;
    height: 23%;
    left: 81px;
    bottom: 96px;
    background-image: url('../assets/comment/message03.png');
  }
  .comment-modal--message4 {
    width: 17%;
    height: 37%;
    right: 86px;
    bottom: 134px;
    background-image: url('../assets/comment/message04.png');
  }

  .comment-modal--left {
    width: 27%;
    height: 8%;
    left: 53px;
    bottom: 30px;
    background-image: url('../assets/comment/leftPaperArrow.png');
  }
  .comment-modal--right {
    width: 10%;
    height: 18%;
    right: 52px;
    bottom: 130px;
    background-image: url('../assets/comment/rightPaperArrow.png');
  }
  .comment-modal--2starBg {
    background-image: url('../assets/comment/2starBg.png');
  }
  .comment-modal--2star {
    background-image: url('../assets/comment/2star.png');
  }
  .comment-modal--2star,
  .comment-modal--2starBg {
    width: 8%;
    height: 7%;
    left: 68px;
    bottom: 220px;
  }

  .comment-modal--3starBg {
    background-image: url('../assets/comment/3starBg.png');
  }
  .comment-modal--3star {
    background-image: url('../assets/comment/3star.png');
  }
  .comment-modal--3star,
  .comment-modal--3starBg {
    width: 11.3%;
    height: 9%;
    left: 33px;
    bottom: 200px;
  }

  .comment-modal--car {
    width: 47%;
    height: 33%;
    right: -6px;
    bottom: 2px;
    background-image: url('../assets/comment/taxi.png');
  }
}

.comment-modal-image:not(.hide) {
  $commentThkDuring: 0.6s;
  $commentStartDelay: 0.3s;
  $commentSecondDelay: 0.9s;
  .comment-modal--message1 {
    opacity: 0;
    animation: fadeIn $commentThkDuring linear $commentStartDelay 1 forwards;
  }
  .comment-modal--message2 {
    opacity: 0;
    animation: fadeIn $commentThkDuring linear $commentStartDelay 1 forwards;
  }

  .comment-modal--message3 {
    opacity: 0;
    animation: fadeIn $commentThkDuring linear $commentSecondDelay 1 forwards;
  }
  .comment-modal--message4 {
    opacity: 0;
    animation: fadeIn $commentThkDuring linear $commentSecondDelay 1 forwards;
  }

  .comment-modal--2star {
    opacity: 0;
    animation: fadeIn $commentThkDuring linear $commentSecondDelay 1 forwards;
  }
  .comment-modal--3star {
    opacity: 0;
    animation: fadeIn $commentThkDuring linear $commentStartDelay 1 forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.comment-modal-price {
  padding: 20px 12px;
  background: #efeff4;
  border-radius: 8px;
  &__item {
    display: flex;
    justify-content: space-between;
    + .comment-modal-price__item {
      margin-top: 12px;
    }
  }
  &__fare {
    color: var(--primaryColor);
    font-size: 15px;
    font-weight: bold;
    .fare {
      font-size: 20px;
    }
  }
  &__origin {
    color: var(--mainTextColor);
    font-size: 15px;
  }
}
.comment-modal-tips {
  margin-top: 12px;
  padding-left: 12px;
  &__item {
    color: #999999;
    font-size: 15px;
    line-height: 1.5;
    display: block;
    text-align: left;
  }
}
.comment-modal-pay {
  margin-top: 50px;
  margin-bottom: 20px;
}
