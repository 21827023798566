.discount-list {
  margin-top: 10px;
  &__item {
    display: flex;
    align-items: center;
    padding: 15px 0px 15px 8px;
    font-size: 1.5rem;
    color: var(--mainTextColor);
    line-height: 2.4rem;
    + .discount-list__item {
      border-top: 1px dashed #d2d2d2;
    }
    &__icon {
      font-size: 2.2rem;
      color: var(--primaryColor);
      margin-right: 8px;
    }
    &__type {
      margin-right: 8px;
    }
    &__price {
      margin-right: auto;
      font-weight: bold;
      color: var(--primaryColor);
    }
    &__status-icon {
      font-size: 2rem;
      &.icon-close-circle {
        &::before {
          color: #d2d2d2;
        }
      }
      &.icon-arrow-right {
        font-size: 2.4rem;
        color: #999999;
      }
      &.disabled {
        color: #d2d2d2;
      }
    }
    &__status {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    &__status-text {
      font-size: 1.5rem;
      line-height: 24px;
      &.disabled {
        color: #999999;
      }
    }
  }
}
