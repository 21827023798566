.taxi-Notification {
  height: 70vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
}

.taxi-Notification-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0 1.2rem;
  min-height: 50%;
  flex: 1;
  margin-bottom: 2.7rem;
}
.taxi-Notification-title {
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 2.4rem;
  padding: 1rem;
  margin-bottom: 1.2rem;
  color: var(--mainTextColor);
}
.taxi-Notification-text {
  font-size: 1.7rem;
  padding: 1rem;
  margin-bottom: 4rem;
  line-height: 2.5rem;
  white-space: pre-line;
  color: #37474f;
}
