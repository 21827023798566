.select-credit-card {
  position: absolute;
  background: var(--systemWhite);
  z-index: 9;
  top: 0;
  width: 100vw;
  min-height: var(--app-height);
  text-align: center;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    &__main {
      color: var(--primaryColor);
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    &__sub {
      color: #999999;
      font-size: 13px;
      text-align: left;
      line-height: 18px;
    }
  }
  &__change-card {
    margin-top: 28px;
  }
  &__card-list {
    margin-top: 12px;
    margin-bottom: 12px;
    .credit-card {
      min-height: 58px;
      &__text {
        p {
          color: var(--mainTextColor);
          font-size: 1.5rem;
        }
      }
      &__button {
        i {
          margin: 1px;
          font-size: 2rem;
          margin-right: 14px;
        }
      }
    }
  }
  &__footer {
    margin-top: auto;
    margin-bottom: 40px;
    &__tips {
      color: var(--primaryColor);
      font-size: 15px;
      margin-bottom: 24px;
    }
  }
  &__block {
    margin-top: 28px;
    background: #efeff4;
    border-radius: 8px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    flex-wrap: wrap;
    &__title {
      color: var(--mainTextColor);
      font-size: 1.7rem;
      font-weight: bold;
    }
    &__value {
      font-size: 2rem;
      min-width: 0;
      text-align: right;
      word-break: break-all;
      font-weight: bold;
      color: var(--primaryColor);
    }
  }
}

.confirm-fare-changed-modal {
  &__content {
    padding: 0 0 10px 0;
    line-height: 24px;
    &__price {
      font-weight: bold;
      &.primary {
        color: var(--primaryColor);
      }
    }
  }
}
