.credit-card {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 12px 0;
  &__img {
    display: flex;
  }
  &__info {
    margin-right: auto;
    margin-left: 10px;
    color: #999999;
    font-size: 13px;
    p {
      margin: 0;
      text-align: left;
      &:nth-of-type(2) {
        margin-top: 2px;
      }
    }
  }
  &__radio {
    display: flex;
    margin-right: 12px;
    & input[type='radio'] {
      position: relative;
      width: 24px;
      height: 24px;
      color: #999;
      border: 2px solid #999;
      border-radius: 50%;
      appearance: none;
      outline: 0;
      cursor: pointer;

      &::before {
        position: absolute;
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: var(--primaryColor);
        border-radius: 50%;
        opacity: 0;
      }

      &:checked {
        border-color: var(--primaryColor);
        background: var(--systemWhite);
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &__label {
    label {
      flex: 1;
      position: relative;
      cursor: pointer;
      font-size: 1.5rem;
      padding: 0 8px 0;
      user-select: none;
      color: #999999;
    }
  }
}
