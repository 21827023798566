@import './Share.scss';
.history {
  display: flex;
  min-height: 110px;
  color: #999;
  position: relative;
  overflow: hidden;
  &__left {
    display: flex;
    flex: 1;
    flex-direction: column;
    &__pay {
      color: var(--mainTextColor);
      font-size: 1.5rem;
      .amount {
        font-weight: bold;
      }
      .divided {
        margin: 0 8px;
        font-size: 1.3rem;
      }
      .method {
        font-size: 1.3rem;
      }
    }

    & > &--header {
      display: flex;
      font-size: 1.6rem;
      color: var(--mainTextColor);

      & > div.history__left__star {
        display: flex;
        align-items: flex-start;
        color: var(--primaryColor);
        font-size: 1.6rem;
        justify-content: flex-end;
        margin-left: 12px;
        & > i {
          transform: translateY(1px);
        }

        & > p {
          font-size: 1.6rem;
          padding: 0 6px;
          color: var(--mainTextColor);
          font-weight: bold;
        }
      }

      & > span {
        font-weight: 600;
      }

      & > span:nth-child(2) {
        font-size: 1.5rem;
        font-weight: 400;
        border-left: #999 solid 1px;
      }
    }

    & > &--body {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-height: 100px;
      align-items: flex-start;
      font-size: 1.3rem;
      padding: 10px 0;
      color: var(--mainTextColor);
      .hidden {
        opacity: 0;
        pointer-events: none;
      }

      & > div {
        display: flex;
        align-items: center;
        min-height: 20px;

        & > i {
          color: #999;
          width: 20px;
          display: flex;
          justify-content: center;
          margin-right: 9px;
        }
      }
      & > div.history__left__address {
        margin: 10px 0 0;
        flex: 1;
        display: flex;
        align-items: center;
      }
      & > div.history__left__location {
        display: flex;
        align-items: center;
      }
      & > div.history__left__down {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
      }

      & > .history__left__car-info {
        display: flex;
        align-items: center;
        margin: 12px 0 0;
        .history__left__car-info__license {
          color: #999999;
          font-size: 1.3rem;
          margin-right: 8px;
        }
        .history__left__car-info__btn {
          color: var(--primaryColor);
          text-decoration: underline;
          font-size: 1.3rem;
        }
      }

      & span {
        text-align: left;
      }

      // ICON 微調部分
      & .icon-car {
        font-size: 2.5rem;
      }

      & .icon-marker {
        font-size: 2rem;
      }

      & .icon-down {
        font-size: 1rem;
        color: var(--primaryColor);
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;

    & > &--header {
      display: flex;
      justify-content: flex-end;

      & > span {
        height: 20px;
        text-align: right;
        font-size: 1.4rem;

        &.active {
          color: var(--primaryColor);
        }
      }
    }

    & > &--body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      .icon-container {
        i {
          font-size: 2.4rem;
          color: #999999;
        }
      }
    }

    & > &--footer {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 10px;
      .history__right__item {
        display: flex;
        justify-content: flex-end;
        + .history__right__item {
          margin-top: 10px;
        }
      }

      & > div.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
    &__status {
      &.systemOrange {
        color: var(--systemOrange);
      }
      &.primary {
        color: var(--primaryColor);
      }
      &.disabled {
        color: #999999;
      }
    }
  }
}
