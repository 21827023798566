.bottom-popup {
  position: absolute;
  width: 100%;
  background-color: var(--systemWhite);
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  overflow: auto;
  z-index: 2;
  border-radius: 16px 16px 0 0;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    z-index: 2;
    opacity: 0;
    &.open {
      opacity: 1;
    }
  }
  &__close-btn {
    position: absolute;
    right: 16px;
    top: 12px;
    cursor: pointer;
    color: #999999;
    i {
      font-size: 2.8rem;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    position: relative;
    &__title {
      font-size: 1.7rem;
      color: var(--mainTextColor);
      line-height: 2.4rem;
    }
  }
  &__content {
    padding: 24px;
  }
}
