.list {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  // background-clip: content-box;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  // -webkit-overflow-scrolling: touch
  .history {
    border-bottom: 1px solid #efeff4;
  }
}

.nodata {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;

  & > div {
    width: 40px;
    height: 60px;
  }

  &__historylist > div {
    background: url('../assets/empty.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  &__blacklist > div {
    background: url('../assets/empty.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  &__preferlist > div {
    background: url('../assets/empty.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  & i {
    font-size: 5rem;
    margin: 20px;
  }

  & p {
    font-size: 1.4rem;
  }
}

.listItem {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 80px;
  padding: 5px 0;
  letter-spacing: 2px;
  border-bottom: 1px solid #efeff4;

  &__text {
    text-align: start;

    & span {
      font-size: 1.5rem;
      color: var(--mainTextColor);
    }

    & span:nth-child(2) {
      font-size: 1.4rem;
      color: #999;
    }
  }

  &__text:nth-child(1) span:nth-child(2) {
    color: var(--primaryColor);
  }

  &__text i {
    color: var(--primaryColor);
  }
}

.drawerModal__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 70px;
  border-bottom: 1px solid #d2d2d2;
  color: var(--primaryColor);
  outline: none;
  font-size: 3rem;
  text-align: center;
  padding: 15px 0;
}

.drawerModal__warning {
  color: var(--systemOrange);
  font-size: 1.6rem;
  margin-top: 12px;
}

.drawerModal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 56px;
  border-bottom: 1px solid #d2d2d2;
  color: var(--primaryColor);
  outline: none;
  font-size: 2rem;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;

  & button {
    height: 100%;
    flex: 1;
    background-color: transparent;
    border: none;
    padding: 0px;
    font: inherit;
    color: inherit;
    cursor: pointer;
  }
}

.drawerModal__button:nth-last-child(1) {
  border-bottom: none;
}

.moreBtn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreBtn:after {
  content: '\2807';
  font-size: 2rem;
}

.sortokBtn {
  position: absolute;
  right: 0;
  margin-right: 20px;
  font-weight: normal;
}

.delBtn {
  height: 20px;
  // width: 2rem;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}

.sortBtn {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sortBtn div {
  position: relative;
  transition: 0.7s;
  width: 20px;
  height: 2px;
  background: var(--mainTextColor);
}

.sortBtn div:before {
  content: '';
  width: 100%;
  height: 2px;
  transition: 0.7s;
  position: absolute;
  transform: translate(-50%, -5px);
  background: var(--mainTextColor);
  left: 50%;
}

.sortBtn div:after {
  content: '';
  width: 100%;
  height: 2px;
  transition: 0.7s;
  position: absolute;
  transform: translate(-50%, 5px);
  background: var(--mainTextColor);
  left: 50%;
}

.deleteItem {
  color: #111;
  text-align: center;
  font-size: 1.4rem;
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
}

.listItem__scrollBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.listItem__header {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 2.1rem;
  color: var(--primaryColor);
  font-weight: 500;

  & i {
    font-size: 2.2rem;
    margin-right: 5px;
    font-weight: bold;
  }
}

.listItem__container {
  height: 80px;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efeff4;
  z-index: 99;
  position: relative;
  background: var(--systemWhite);
}

.listItem__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #efefef;
  font-size: 2rem;
  min-width: 35px;
  height: 35px;
  color: #999;
  margin-right: 20px;

  & .icon-heart-2 {
    transform: translateY(1px);
  }
}

.listItem__icon.normal {
  font-size: 20px;
  background: transparent;
}

.listItem__item {
  flex: 1;
  max-width: calc(100% - 80px);
}

.general-modal__text {
  margin: 14px 0 10px;
  color: var(--mainTextColor);
}

.inputItem__span {
  font-size: 1.4rem;
  text-align: center;
  color: var(--mainTextColor);
  margin-bottom: 9px;

  & span {
    color: var(--primaryColor);
  }
}

.inputItem__input {
  padding: 6px 15px 7px 15px;
  outline: none;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  font-size: 1.4rem;
  color: var(--mainTextColor);
  background: var(--systemWhite);
  height: 30px;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 3px;
}

.inputItem__input:focus {
  border: 1px solid var(--primaryColor);
  // background: #EFEFF4;
}

.listfliter {
  position: absolute;
  width: 100%;
  height: 100%;
}

.listfliter:checked {
  outline: none;
}

.addNewPrefer {
  z-index: 250;
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--primaryColor);
  box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.4);

  &::before,
  &::after {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #efefef;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
// iphone 5 規則
//
@media (max-width: 355px) {
  .history__left > .history__left--header {
    font-size: 1.4rem;
  }

  .history__left > .history__left--header span:nth-child(2) {
    font-size: 1.3rem;
  }
}
@media (max-width: 330px) {
  .history__left > .history__left--header {
    font-size: 1.3rem;
  }

  .history__left > .history__left--header span:nth-child(2) {
    font-size: 1.2rem;
  }
}
