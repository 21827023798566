.tab-bar {
  min-height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: -15px -15px 15px rgba(255, 255, 255, 0), 15px 15px 15px rgba(0, 0, 0, 0),
    inset 0px 4px 5px rgba(255, 255, 255, 0.8), inset 0px -4px 5px rgba(0, 0, 0, 0.05);
  &__headerItem {
    background: linear-gradient(0deg, transparent 4px, white 0);
    display: flex;
    flex: 1;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-right: 1px;
    font-weight: 600;
    color: #999999;
    &:nth-child(1).selected ~ .tab-bar__slider {
      left: 0;
    }

    &:nth-child(2).selected ~ .tab-bar__slider {
      left: 50%;
    }
    &.selected {
      color: var(--primaryColor);
    }
    &__title {
      font-size: 13px;
    }
    &__subtitle {
      margin-top: 6px;
      font-size: 16px;
    }
    &__text-container {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  &__slider {
    position: absolute;
    bottom: 0;
    left: -50%;
    width: 50%;
    height: 4px;
    background: var(--primaryColor);
    transition: 0.05s;
    transition-timing-function: ease-in-out;
  }
}
