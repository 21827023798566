.payment-failed {
  position: absolute;
  background: var(--systemWhite);
  z-index: 9;
  top: 0;
  width: 100vw;
  height: var(--app-height);
  text-align: center;
  overflow-y: scroll;
  padding: 0 16px 20px;
  display: flex;
  flex-direction: column;
  &__title {
    &__text {
      color: var(--mainTextColor);
      font-size: 20px;
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    &__img {
      width: 116px;
    }
  }
  &__description {
    text-align: left;
    margin-top: 56px;
    &__title {
      color: var(--mainTextColor);
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
  &__list {
    list-style: none;
    text-align: left;
    &.note {
      padding: 0 0 0 8px;
    }
    &__item {
      color: var(--mainTextColor);
      font-size: 15px;
      line-height: 22px;
      + .payment-failed__list__item {
        margin-top: 7px;
      }
    }
  }
  &__note {
    margin-top: 32px;
    &__title {
      background-color: #efeff4;
      text-align: left;
      padding: 5px 8px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      color: var(--mainTextColor);
    }
  }
  &__line {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    &__button {
      border: none;
      width: 140px;
      background: transparent;
      img {
        width: 100%;
      }
    }
  }
  &__footer {
    margin-top: 40px;
    &__button {
      + .payment-failed__footer__button {
        margin-top: 12px;
      }
    }
  }
}
