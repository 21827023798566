/* switchBox */
.switch-box {
  position: relative;
  width: 51px;
  height: 31px;
  border: 2px solid #e5e5e5;
  border-radius: 50em;
  transition: 0.2s;
  box-sizing: border-box;

  &--checked {
    background: #4cd964;
    border-color: #4cd964;
    transition: 0.2s;

    &:after {
      right: 0;
      transition: 0.2s;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 1px 1px 5px -1px #999;
    transition: 0.3s;
  }
}
