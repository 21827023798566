.statusPage {
  position: absolute;
  background: var(--systemWhite);
  z-index: 9;
  top: 0;
  width: 100vw;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;

  &-header {
    position: relative;
    padding-top: 20px;
    color: var(--mainTextColor);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.busy-notice {
      & > p {
        position: absolute;
        bottom: -16px;
      }
    }

    & > span {
      white-space: pre-line;
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 16px;
      font-weight: 600;
    }
    & > p {
      width: 100%;
      padding: 0 15%;
      font-size: 1.5rem;
      white-space: pre-line;
      line-height: 2.5rem;
      font-weight: 400;
    }

    & a {
      color: #007afe;
      text-decoration: underline;
    }
  }

  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-tips {
    padding: 35px 15%;
    .warning-tips {
      font-size: 1.5rem;
      color: var(--systemOrange);
      line-height: 2.3rem;
    }
  }
  &-cancel-warning {
    padding: 10px 20px;
    width: 100%;
    color: #999999;
    font-size: 1.5rem;
    text-align: left;
    .primary {
      color: var(--primaryColor);
    }
  }
  &-footer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;

    &.two-btn {
      padding-right: 6px;
      padding-left: 6px;
      flex-wrap: nowrap;
      & > button {
        margin: 0 6px;
      }
    }

    & > button {
      margin: 0 30px 16px 30px;
      height: 44px;
      width: 100%;
      border-radius: 4px;
      text-align: center;
      font-size: 1.6rem;
      position: relative;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > button.btn-cancel {
      color: var(--mainTextColor);
      border: 1px solid #d2d2d2;
      background-color: transparent;
    }

    & > button.btn-default {
      color: var(--primaryColor);
      border: 1px solid var(--primaryColor);
      background-color: transparent;
    }

    & > button.btn-alert {
      color: #f05151;
      border: 1px solid #f05151;
      background-color: transparent;
    }

    & > button.btn-fill {
      color: var(--systemWhite);
      border: none;
      background-color: var(--primaryColor);
    }
  }
}

.statusPage-img {
  $screenImageMaxSize: 100vw;
  position: relative;

  &.h-200 {
    height: 200px;
  }

  &.margin-top-0 {
    padding: 20vh 0;
    margin-top: 0;
  }

  &.rwd-scale {
    @media (max-width: 374px) {
      transform: scale(0.85);
    }
  }

  &.rwd-scale.rwd-driver-accident {
    @media (max-width: 374px) {
      transform: scale(0.75) translateY(35px);
    }
  }

  &.margin-bottom {
    margin-bottom: 50px;
  }

  .statusPage-asset {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .statusPage-back {
    position: absolute;
    border-radius: 50%;
    max-height: 157px;
    height: calc(#{$screenImageMaxSize} * 0.42);
    max-width: 274px;
    width: calc(#{$screenImageMaxSize} * 0.73);
    background: #ebf4ff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .statusPage-back-s {
    position: absolute;
    border-radius: 50%;
    height: 172px;
    width: 300px;
    background: #ebf4ff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .statusPage-phone_glass {
    position: absolute;
    max-height: 205px;
    max-width: 317px;
    height: calc(#{$screenImageMaxSize} * 0.55);
    width: calc(#{$screenImageMaxSize} * 0.84);
    background-image: url('../assets/status/phone_glass.png');
    transform: translate(-50%, -56%);
  }

  .statusPage-phone {
    max-height: 159px;
    max-width: 250px;
    height: calc(#{$screenImageMaxSize} * 0.42);
    width: calc(#{$screenImageMaxSize} * 0.67);
    transform: translate(-65%, -55%);
    background-image: url('../assets/comment/phone.png');
  }

  .statusPage-phone-s {
    height: 159px;
    width: 250px;
    transform: translate(-50%, -30%);
    background-image: url('../assets/comment/phone.png');
  }

  .statusPage-waitTaxi-taxi {
    max-height: 101px;
    max-width: 141px;
    height: calc(#{$screenImageMaxSize} * 0.27);
    width: calc(#{$screenImageMaxSize} * 0.38);
    background-image: url('../assets/status/taxi.png');
    top: 50%;
    left: 50%;
    animation: taxiMove 2s ease-in-out infinite;
  }

  .statusPage-error {
    max-height: 58px;
    max-width: 68px;
    height: calc(#{$screenImageMaxSize} * 0.154);
    width: calc(#{$screenImageMaxSize} * 0.181);
    background-image: url('../assets/status/error.png');
    animation: errorAlertMove 1.6s linear infinite;
  }

  .statusPage-glass {
    max-height: 205px;
    max-width: 84px;
    height: calc(#{$screenImageMaxSize} * 0.59);
    width: calc(#{$screenImageMaxSize} * 0.22);
    transform: translate(85%, -56%);
    background-image: url('../assets/status/glass.png');
  }

  .statusPage-info {
    height: 222px;
    width: 324px;
    transform: translate(-50%, -50%);
    background-image: url('../assets/status/info.png');
  }

  .statusPage-arrived {
    max-height: 210px;
    max-width: 245px;
    height: calc(#{$screenImageMaxSize} * 0.56);
    width: calc(#{$screenImageMaxSize} * 0.65);
    transform: translate(-50%, -50%);
    background-image: url('../assets/status/arrived.png');
  }

  .statusPage-taxi-animate {
    max-height: 129px;
    max-width: 179px;
    height: calc(#{$screenImageMaxSize} * 0.34);
    width: calc(#{$screenImageMaxSize} * 0.48);
    transform: translate(-73%, -24%);
    background-image: url('../assets/status/taxi-ani.png');
    background-size: 200% 100%;
    animation: taxiLight 1s steps(2) infinite;
  }

  .statusPage-marker {
    height: 35px;
    width: 25px;
    background-image: url('../assets/status/marker.png');
    animation: markerDown 1.5s linear infinite;
  }

  .statusPage-sign {
    height: 104px;
    width: 70px;
    background-image: url('../assets/status/sign.png');
    transform: translate(-55%, -123.6%);
    animation: signMove 0.2s linear 0.3s 1 forwards;
  }

  .statusPage-waveBox {
    height: 104px;
    display: flex;
    flex-direction: column;
    transform: translate(-81px, -115px);
  }

  .statusPage-wave {
    background-size: contain;
    background-repeat: no-repeat;
    width: 42px;
    height: 46px;
    opacity: 0;
    background-image: url('../assets/status/wave.png');
    transform-origin: 100% 100%;
    animation: waveScale 1.2s linear 0.8s infinite;
  }

  .statusPage-marker-line1 {
    width: 67px;
    height: 3px;
    transform: skew(-60deg) rotate(-1deg) translate(-160px, -27px);
    background-color: #0091ea;
    animation: widthGrow 1.5s linear infinite;
  }

  .statusPage-marker-line2 {
    height: 17px;
    width: 3px;
    transform: skew(-36deg) translate(-76px, -24px);
    background: linear-gradient(transparent 50%, #0091ea 0, #0091ea 100%);
    background-size: 3px 34px;
    animation: heightGrow 1.5s linear infinite;
  }

  .statusPage-marker-line3 {
    width: 33px;
    height: 3px;
    transform: skew(-60deg) rotate(-1deg) translate(-80px, -11px);
    background-color: #0091ea;
    animation: widthGrow2 1.5s linear infinite;
  }

  .statusPage-driver-error {
    height: 305px;
    width: 274px;
    transform: translate(-50%, -50%);
    background-image: url('../assets/status/driver-error.png');
  }

  .statusPage-driver-error-info {
    height: 40px;
    width: 28px;
    opacity: 0;
    transform: translate(-50%, -50%);
    background-image: url('../assets/status/driver-error-info.png');
    animation: opacityAni 1.5s linear infinite;
  }

  .statusPage-driver-accident {
    height: 190px;
    width: 257px;
    transform: translate(-50%, -50%);
    background-image: url('../assets/status/driver-accident.png');
  }

  .statusPage-driver-accident-sorry {
    height: 65px;
    width: 90px;
    transform: translate(-122%, -232%);
    opacity: 0;
    background-image: url('../assets/status/driver-accident-sorry.png');
    animation: driverAccidentOpacitySorry 4.8s linear infinite;
  }

  .statusPage-driver-accident-board {
    height: 140px;
    width: 80px;
    transform: translate(23%, -121%);
    opacity: 0;
    background-image: url('../assets/status/driver-accident-board.png');
    animation: driverAccidentOpacityBoard 4.8s linear infinite;
  }
}

.statusPage-list {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  margin-top: 17px;
  padding: 0 20px;

  & > div {
    height: 40px;
    border-top: 1px solid #d2d2d2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span {
      font-size: 1.6rem;
      color: var(--primaryColor);
      display: flex;
      align-items: center;
    }
    & > span:nth-child(1) {
      white-space: nowrap;
      color: var(--mainTextColor);
    }
    & .sp-word {
      padding: 5px;
      border-radius: 4px;
      margin-right: 3px;
      background-color: #ecf7f4;
    }
  }

  & > div:nth-last-child(1) {
    border-bottom: 1px solid #d2d2d2;
  }
}

@keyframes taxiLight {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 200%;
  }
}

@keyframes waveScale {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(0.8);
  }
}

@keyframes opacityAni {
  0%,
  100% {
    opacity: 0;
  }
  40%,
  70% {
    opacity: 1;
  }
}

@keyframes driverAccidentOpacityBoard {
  0%,
  25%,
  100% {
    opacity: 0;
  }
  35%,
  90% {
    opacity: 1;
  }
}

@keyframes driverAccidentOpacitySorry {
  0%,
  40%,
  100% {
    opacity: 0;
  }
  50%,
  90% {
    opacity: 1;
  }
}

@keyframes signMove {
  0% {
    transform: translate(-55%, -123.6%);
  }
  100% {
    transform: translate(-55%, -114%);
  }
}

@keyframes widthGrow {
  0% {
    width: 0;
  }
  25%,
  100% {
    width: 67px;
  }
}

@keyframes heightGrow {
  0%,
  25% {
    background-position-y: 0;
  }
  50%,
  100% {
    background-position-y: 17px;
  }
}

@keyframes widthGrow2 {
  0%,
  50% {
    width: 0;
  }
  75%,
  100% {
    width: 33px;
  }
}

@keyframes markerDown {
  0%,
  50% {
    opacity: 0;
    transform: translate(-174%, -155.6%);
  }
  75%,
  100% {
    opacity: 1;
    transform: translate(-174%, -127%);
  }
}

@keyframes taxiMove {
  0% {
    transform: translate(1%, -125%);
    opacity: 0;
  }
  20% {
    transform: translate(1%, -125%);
    opacity: 1;
  }
  60% {
    transform: translate(-99%, -25%);
    opacity: 1;
  }
  90%,
  100% {
    transform: translate(-99%, -25%);
    opacity: 0;
  }
}

@keyframes errorAlertMove {
  0% {
    transform: translate(-121%, -195%);
  }
  18.75%,
  31.25% {
    transform: translate(-121%, calc(-195% + 4px));
  }
  67.75%,
  81.25% {
    transform: translate(-121%, calc(-195% - 3px));
  }
  100% {
    transform: translate(-121%, -195%);
  }
}
