.history-fee-detail {
  color: var(--mainTextColor);
  .history-fee-detail__footer {
    padding: 12px 0;
    border-top: 1px dashed #d2d2d2;
    font-weight: bold;
  }
  .history-fee-detail__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    + .history-fee-detail__item {
      margin-top: 10px;
    }
  }
  .history-fee-detail__item__title {
    font-size: 1.3rem;
  }
}
