.history-detail-page {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: #efeff4;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  .history-detail-scroll-container {
    overflow-y: auto;
    &.footer-padding {
      padding-bottom: 80px;
    }
  }
  .history-detail-block {
    padding: 15px;
    + .history-detail-block {
      margin-top: 8px;
    }
    &__title {
      font-size: 1.5rem;
      font-weight: bold;
      color: var(--mainTextColor);
    }
  }
  .history-list-btn {
    width: 100%;
    background: var(--systemWhite);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    + .history-list-btn {
      border-top: 1px solid #efeff4;
    }
    &__title {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
    &__icon {
      font-size: 2.4rem;
      color: #999999;
    }
  }
  .history-detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--systemWhite);
    padding: 17px 20px;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.06);
  }
  .empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5rem;
    color: var(--mainTextColor);
    background: var(--systemWhite);
  }
}
