.banner {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > img {
    width: 100%;
  }
}
