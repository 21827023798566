.history-payment-detail {
  color: var(--mainTextColor);
  font-size: 1.3rem;
  .history-payment-detail__block {
    padding: 12px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    + .history-payment-detail__block {
      border-top: 1px dashed #d2d2d2;
    }
  }
  .history-payment-detail__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .primary {
      color: var(--primaryColor);
    }
  }
  .history-payment-detail__desc {
    text-align: left;
    color: #999999;
    margin-top: 6px;
  }
}
