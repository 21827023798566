.history-detail-block {
  padding: 15px;
  background: var(--systemWhite);
  color: var(--mainTextColor);
  font-size: 1.3rem;
  &.list-btn {
    padding: 0;
  }
  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
    color: var(--mainTextColor);
    text-align: left;
  }
  &__content {
    padding: 12px 0;
  }
}
