.credit-card-payment {
  position: absolute;
  background: var(--systemWhite);
  z-index: 9;
  top: 0;
  width: 100vw;
  height: var(--app-height);
  text-align: center;
  overflow: scroll;
  padding: 0 16px;
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 0;
    &__main {
      color: var(--primaryColor);
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 4px;
    }
    &__sub {
      color: #999999;
      font-size: 13px;
      text-align: left;
      line-height: 18px;
    }
  }
  &__card-type {
    display: flex;
    align-items: center;
    .card + .card {
      margin-left: 4px;
    }
  }
  &__card-detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__info {
    color: var(--mainTextColor);
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    text-align: left;
    padding-top: 23px;
    img {
      height: 38px;
      width: auto;
      margin-right: 5px;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
  &__footer {
    margin-top: 24px;
    margin-bottom: 20px;
    &__tips {
      color: var(--primaryColor);
      font-size: 15px;
      margin-bottom: 24px;
    }
  }
  &__block {
    margin-top: 28px;
    background: #efeff4;
    border-radius: 8px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    flex-wrap: wrap;
    &__title {
      color: var(--mainTextColor);
      font-size: 1.7rem;
      font-weight: bold;
    }
    &__value {
      font-size: 2rem;
      min-width: 0;
      text-align: right;
      word-break: break-all;
      font-weight: bold;
      color: var(--primaryColor);
    }
  }
}
.card-detail__field {
  margin-top: 16px;
  .field__title {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 8px;
    text-align: left;
    color: var(--mainTextColor);
  }
  &--full {
    width: 100%;
  }
  &--half {
    width: calc(50% - 6px);
  }
  .field__error {
    color: var(--systemRed);
    font-size: 13px;
    margin-top: 4px;
    margin-left: 4px;
    text-align: left;
  }
}
.card-detail__number {
  flex-grow: 1;
}
.card-detail__expiration-date {
  width: calc(50% - 6px);
}
.card-detail__ccv {
  width: calc(50% - 6px);
}
.card-detail__input {
  width: 100%;
  height: 44px;
  padding: 10px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  &--error {
    border: 1px solid var(--systemRed);
  }
}
.card-detail__checkbox {
  display: flex;
  margin-right: auto;
  margin-top: 20px;
  align-items: center;
  padding-left: 4px;
  span {
    display: flex;
    font-size: 1.6rem;
    color: var(--mainTextColor);
    position: relative;
    &::before {
      content: '';
      width: 23px;
      height: 23px;
      border: 2px solid #999;
      border-radius: 3px;
      box-sizing: border-box;
      margin-right: 8px;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 3px;
      left: 9px;
      width: 6px;
      height: 14px;
      border-style: solid;
      border-color: var(--systemWhite);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg) translate(-3px, 0px);
      z-index: 2;
      opacity: 0;
    }
  }
  input:checked {
    & + span {
      &::before {
        border-color: var(--primaryColor);
        background: var(--primaryColor);
      }
      &:after {
        opacity: 1;
      }
    }
  }
}
