.ripple {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	span {
		transform: scale(0);
		border-radius: 100%;
		position: absolute;
		opacity: 0;
		-webkit-animation-name: rippleA;
		animation-name: rippleA;
		-webkit-animation-timing-function: ease;
		animation-timing-function: ease;
		animation-fill-mode: forwards;
	}
}

@keyframes rippleA {
	0% {
		transform: scale(0);
		opacity: 0.1;
	}
	100% {
		opacity: 0.3;
		transform: scale(4);
	}
}

@-webkit-keyframes rippleA {
	0% {
		transform: scale(0);
		opacity: 0.1;
	}
	100% {
		opacity: 0.3;
		transform: scale(4);
	}
}