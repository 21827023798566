.modali__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: var(--app-height);
  background-color: #000;
  opacity: 0.5;
}

.modali__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 25px;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: var(--app-height);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modali__wrapper__drawer {
  align-items: flex-end;
  padding: 0 8px;

  & .modali__content {
    padding: 0;
  }

  & .modali__body {
    padding: 0 10px;
    border-radius: 16px;
  }

  & .modali__button {
    border-radius: 16px;
  }
}

.modali {
  z-index: 100;
  background: #f7f7f7;
  position: relative;
  margin: 17.5px auto;
  border-radius: 14px;
  pointer-events: none;
  width: 100%;
}

.modali__transparent {
  background: transparent;
}

.modali__content {
  pointer-events: auto;
  padding: 0 2rem;
  height: 100%;
  overflow-y: scroll;
}

.modali__fullContent {
  .modali__content {
    padding: 0;
  }
}

.modali__close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  opacity: 1;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.modali__header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 15px 5px 15px;
}

.modali__header .modali__title {
  font-size: 1.8rem;
  color: var(--mainTextColor);
  text-align: center;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
}

.modali__close__button {
  padding: 1rem;
  background: #f7f7f7;
  margin: -10px -10px -10px auto;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: var(--mainTextColor);
  opacity: 0.3;
  cursor: pointer;
  border: none;
  outline: 0 !important;
}

.modali__close__button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.modali__body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 5px;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0px;
}

.modali__body__style {
  color: var(--mainTextColor);
  text-align: center;
  font-size: 1.4rem;
  margin: 0 0 2rem 0;
}

.modali__footer {
  padding: 0;
  display: flex;
  justify-content: space-around;
  // flex-wrap: wrap;
}

@media (min-width: 1025px) {
  .modali__open {
    padding-right: 15px;
  }
}

.modali__open {
  overflow: hidden;
}

/* Size Classes */

.modali__size__regular {
  // min-width: 100%;
  max-width: 270px;
}

.modali__size__medium {
  max-width: 300px;
}

@media (min-width: 500px) {
  .modali__size__regular {
    min-width: 468px !important;
  }
}

.modali__size__large {
  min-width: 100%;
  max-width: calc(100% - 32px);
}

.modali__size__fullScreen {
  min-width: 100vw;
  max-width: 100vw;
  height: var(--app-height);
  margin: 0;
  border-radius: 0;
  overflow-y: scroll;
}

/* Position Classes */

.modali__wrapper__fullScreen {
  padding: 0;
}

.modali__wrapper__centered {
  top: 40% !important;
}

@media (min-width: 1000px) {
  .modali__wrapper__centered {
    top: 0 !important;
    display: flex !important;
    align-items: center;
  }
}

/* Button Classes */

.modali__button {
  font-size: 1.8rem;
  font-weight: 400;
  min-height: 44px;
  height: 56px;
  flex: 1;
  border: none;
  border-radius: 5px;
  padding: 3px 10px;
  background: #f7f7f7;
  color: var(--primaryColor);
  line-height: 22px;
  letter-spacing: 0px;
}

.modali__button__cancel {
  background: #f7f7f7;
  color: #999;
}

.modali__button__destructive {
  background: #f7f7f7;
  color: var(--primaryColor);
  font-weight: 500;
}

.modali__button__default {
  background: #247ba0;
  color: #f7f7f7;
}

.modali__button__muti {
  border-top: 1px solid #d2d2d2;
  border-radius: 0;
}

.modali__button__two {
  border-top: 1px solid rgba(1, 1, 1, 0.2);
  height: 44px;
}

.modali__button__two:nth-child(1) {
  border-radius: 0 0 0 5px;
}

.modali__button__two:nth-child(2) {
  border-radius: 0 0 5px 0;
}

/* Animation Classes */

.modali__animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.modali__animation__fade__in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
