.discount {
  display: flex;
  align-items: flex-start;
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 1.2rem);
    margin-right: 1.2rem;
    font-size: 1.5rem;
    &--error {
      input.discount__input-wrapper__input {
        border: 1px solid var(--systemRed);
      }
    }
  }
  &__input-wrapper__input {
    background-color: var(--systemWhite);
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 1.2rem;
    width: 100%;
    height: 4.4rem;
  }
  &__input-wrapper__error {
    color: var(--systemRed);
    font-size: 1.3rem;
    margin: 4px 0 0;
    text-align: left;
  }
  &__button {
    margin: 3px 0;
  }
}
