.fliter {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(2, 2, 2, 0.7);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  z-index: 4;
}

.SideBar {
  min-width: 224px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  transform: translateX(-100%);
  transition: 0.3s;
  background: var(--systemWhite);
  color: var(--mainTextColor);
  box-shadow: none;

  &__items {
    display: flex;
    padding: 15px 0;
    flex-direction: column;
    flex: 1;
  }

  &__item {
    height: 44px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    // ICON 微調
    & .icon-history-3 {
      font-size: 1.9rem;
    }

    & .icon-car-2 {
      font-size: 1.5rem;
    }

    & .icon-heart-2 {
      font-size: 1.8rem;
    }

    & .icon-contact {
      font-size: 1.8rem;
    }

    & .icon-question {
      font-size: 2.4rem;
    }

    & div {
      font-size: 2rem;
      color: var(--mainTextColor);
      margin-right: 20px;
    }

    & div:nth-child(2) {
      display: flex;
      color: var(--mainTextColor);
      margin: 0;
      width: 60px;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    & span {
      font-size: 1.6rem;
      position: relative;
    }
  }

  &__redDot {
    position: absolute;
    top: -2px;
    right: -28px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--systemRed);
  }
}

.fliter.active {
  pointer-events: auto;
  opacity: 1;
}

.fliter.inActive {
  transition-delay: 0.1s;
}

.SideBar.active {
  transform: translateX(0);
  box-shadow: 0px 15px 0px 15px var(--primaryColor);
}

.SideBar.inActive {
  transition-delay: 0.1s;
}
