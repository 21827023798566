.header {
  display: flex;
  align-items: center;
  background: var(--systemWhite);
  z-index: 2;
  color: var(--mainTextColor);
  position: relative;
  min-height: 44px;

  &__noHeight {
    min-height: 0;
  }

  &__menuBar {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 44px;

    div:nth-child(1) {
      position: relative;
      // transition: .7s;
      width: 16px;
      height: 2px;
      background: var(--primaryColor);
    }

    div:nth-child(1):before {
      content: '';
      top: 0;
      width: 100%;
      height: 2px;
      // transition: .7s;
      position: absolute;
      transform: translate(-50%, -7px);
      background: var(--primaryColor);
    }

    div:nth-child(1):after {
      content: '';
      top: 0;
      width: 100%;
      height: 2px;
      // transition: .7s;
      position: absolute;
      transform: translate(-50%, 7px);
      background: var(--primaryColor);
    }
  }

  &__backBar {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    left: 0;
    height: 44px;

    div:nth-child(1) {
      position: relative;
      width: 14px;
      height: 2px;
      background: var(--primaryColor);
      // transition: .7s;
      transform: scale(1.3);
    }

    div:nth-child(1):before {
      content: '';
      height: 2px;
      position: absolute;
      top: 0;
      background: var(--primaryColor);
      // transition: .7s;
      width: 50%;
      transform: translate(-100%, 3px) rotate(45deg);
    }

    div:nth-child(1):after {
      content: '';
      height: 2px;
      position: absolute;
      top: 0;
      background: var(--primaryColor);
      // transition: .7s;
      width: 50%;
      transform: translate(-100%, -3px) rotate(-45deg);
    }
  }

  &__text {
    color: var(--primaryColor);
    font-weight: 400;
    font-size: 1.4rem;
  }

  &__close {
    position: absolute;
    right: 0;
    align-self: flex-start;
    padding: 10px 10px;

    div {
      position: relative;
      width: 10px;
      height: 2px;
      transform: rotate(45deg);
      background: black;
    }

    div:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 2px;
      transform-origin: left;
      transform: rotate(90deg) translateX(-50%);
      background: black;
    }
  }

  &__redDot {
    position: absolute;
    top: 8px;
    right: 2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--systemRed);
  }
}
