.App {
  width: 100vw;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.shareModal {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    margin: 0 10px;
  }
}

.userAgreement {
  max-height: 60vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &--scroll {
    min-height: calc(60vh + 1px);
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      text-align: left;
      font-size: 1.4rem;
      margin-bottom: 20px;
      width: 100%;
    }

    & > div {
      min-width: 70px;
      display: flex;
      margin: 10px 0;
      align-items: center;

      & > input {
        position: relative;
        width: 20px;
        height: 20px;
        color: #999;
        border: 2px solid #999;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

        &::before {
          position: absolute;
          content: '';
          display: block;
          top: 5px;
          left: 8px;
          width: 4px;
          height: 9px;
          border-style: solid;
          border-color: var(--systemWhite);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) translate(-3px, 0px);
          opacity: 0;
        }
      }

      & > input:checked {
        color: var(--systemWhite);
        border-color: var(--primaryColor);
        background: var(--primaryColor);

        &::before {
          opacity: 1;
        }
      }

      & > label {
        flex: 1 1;
        min-width: 50px;
        position: relative;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0 8px 0;
        -webkit-user-select: none;
        user-select: none;
        color: #999;
        text-align: left;
      }
    }
  }
}

.loadingFliter {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(2, 2, 2, 0.6);
  transition: 0.5s;
  opacity: 1;
  pointer-events: auto;
  z-index: 4;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    color: var(--primaryColor);
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.container {
  flex: 1;
  width: 100vw;
  height: calc(100vh - 4.4rem);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.statusPage {
  position: absolute;
  background: #fefefe;
  z-index: 9;
  top: 0;
  width: 100vw;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;

  &__header {
    min-height: 44px;
    width: 100%;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    overflow: scroll;
  }

  &__title {
    color: #111;

    & p {
      line-height: 2.5rem;
      font-size: 1.5rem;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 2;

    & .taxi-image {
      width: 160px;
      height: 170px;
      background: url('./assets/taxi.png');
      background-size: contain;
    }

    & .back-image-getOn {
      background: url('./assets/back-2.png');
      width: 240px;
      height: 150px;
      background-size: cover;
      position: relative;
    }

    & .signal-image {
      background: url('./assets/signal.png');
      background-size: cover;
      width: 70px;
      height: 77px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    & .statusPage__btn {
      width: 120px;
      margin: 20px;
      color: white;
      background: var(--primaryColor);
      font-size: 16px;
    }
  }

  &__list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__listItem {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    align-items: center;
    min-height: 40px;
    border-top: 1pt solid #d2d2d2;
    color: var(--mainTextColor);

    & span:nth-child(1) {
      min-width: 80px;
      text-align: left;
    }

    & span:nth-child(2) {
      flex: 1;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--primaryColor);
    }

    & div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    & p {
      font-size: 1.3rem;
      color: #999;
    }
  }

  &__listItem:nth-last-child(1) {
    border-bottom: 1px solid #d2d2d2;
  }

  &__button {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__ch {
    position: relative;

    & .back-image {
      background: url('./assets/backcar.png');
      width: 200px;
      height: 130px;
      background-size: cover;
      position: relative;
    }

    & .circle-path {
      width: 40px;
      height: 40px;
      // background: rgba(1,1,1,.3);
      position: absolute;
      border-radius: 50%;
      top: 60px;
      left: 40px;
      animation: glassAnimate 3s ease 1s infinite;
    }

    & .circle-glass {
      width: 155px;
      position: absolute;
      height: 218px;
      background: url('./assets/glass.png');
      background-size: contain;
      top: -65px;
      left: -70px;
      animation: reverseGlassAnimate 3s ease 1s infinite;
    }
  }

  &__cn {
    position: relative;

    & .back-image {
      background: url('./assets/backcar-2.png');
      width: 130px;
      height: 75px;
      background-size: cover;
      display: flex;
      justify-content: center;
      position: relative;
    }

    & .circle-glass {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90px;
      position: absolute;
      height: 155px;
      background: url('./assets/glass-2.png');
      background-size: contain;
      background-repeat: no-repeat;
      animation: glassAnimate2 2s linear 1s infinite;
    }

    & span {
      font-size: 1.8rem;
      font-weight: 400;
      color: #fe7575;
      margin-top: 100px;
      line-height: 30px;
    }
  }

  &__ca {
    position: relative;

    & .back-image {
      background: url('./assets/back.png');
      width: 200px;
      height: 170px;
      background-size: cover;
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;
    }
    & .back-image-getOn {
      background: url('./assets/back-2.png');
      width: 240px;
      height: 150px;
      background-size: cover;
      position: relative;
    }

    & .signal-image {
      background: url('./assets/signal.png');
      background-size: cover;
      width: 70px;
      height: 77px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    & .man-image {
      background: url('./assets/man.png');
      width: 30px;
      position: absolute;
      height: 60px;
      background-size: contain;
      bottom: 15px;
      animation: manAnimate 3s ease infinite;
    }

    & .taxi-image {
      width: 130px;
      position: absolute;
      height: 55px;
      background: url('./assets/taxi-2.png');
      background-size: contain;
      bottom: 5px;
      right: 0;
      background-repeat: no-repeat;
      animation: taxiAnimate 3s ease infinite;
    }
  }

  &__btn {
    height: 40px;
    width: 100px;
    border-radius: 5px;
    color: var(--primaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primaryColor);
    position: relative;
    text-decoration: none;

    & span {
      font-size: 1.6rem;
      text-decoration: none;
    }

    & a {
      color: var(--primaryColor);
      width: 100%;
      height: 100%;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
    }
  }

  &__btn.retry {
    min-height: 44px;
    width: 100%;
    border-radius: 5px;
    background: var(--primaryColor);
    color: var(--systemWhite);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primaryColor);

    & span {
      font-size: 1.6rem;
    }
  }

  &__footer {
    min-height: 20%;
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.navbar {
  display: flex;
  position: relative;
  align-items: center;
  .header {
    &__menuBar {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      overflow: hidden;
      height: 44px;
      margin: 0 4px;
      flex-shrink: 0;
      width: 36px;

      div:nth-child(1) {
        position: relative;
        // transition: .7s;
        width: 16px;
        height: 2px;
        background: var(--primaryColor);
      }

      div:nth-child(1):before {
        content: '';
        top: 0;
        width: 100%;
        height: 2px;
        // transition: .7s;
        position: absolute;
        transform: translate(-50%, -7px);
        background: var(--primaryColor);
      }

      div:nth-child(1):after {
        content: '';
        top: 0;
        width: 100%;
        height: 2px;
        // transition: .7s;
        position: absolute;
        transform: translate(-50%, 7px);
        background: var(--primaryColor);
      }
    }

    &__backBar {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      overflow: hidden;
      left: 0;
      height: 44px;
      margin: 0 4px;
      flex-shrink: 0;
      width: 36px;

      div:nth-child(1) {
        position: relative;
        width: 14px;
        height: 2px;
        background: var(--primaryColor);
        // transition: .7s;
        transform: scale(1.3);
      }

      div:nth-child(1):before {
        content: '';
        height: 2px;
        position: absolute;
        top: 0;
        background: var(--primaryColor);
        // transition: .7s;
        width: 50%;
        transform: translate(-100%, 3px) rotate(45deg);
      }

      div:nth-child(1):after {
        content: '';
        height: 2px;
        position: absolute;
        top: 0;
        background: var(--primaryColor);
        // transition: .7s;
        width: 50%;
        transform: translate(-100%, -3px) rotate(-45deg);
      }
    }

    &__redDot {
      position: absolute;
      top: 8px;
      right: 2px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--systemRed);
    }
  }
}

@keyframes manAnimate {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  20% {
    transform: scale(0.8);
    opacity: 0;
  }

  25% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  70% {
    transform: scale(1);
    opacity: 1;
  }

  75% {
    transform: scale(1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes taxiAnimate {
  0% {
    transform: translate(100%, 0px);
  }

  20% {
    transform: translate(0%, 0px);
  }

  75% {
    transform: translate(0%, 0px);
  }

  100% {
    transform: translate(-150%, 0px);
  }
}

@keyframes glassAnimate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes reverseGlassAnimate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.notification-banner {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 44px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  color: white;
  background: var(--primaryColor);
  transition: 0.5s;

  & i {
    position: absolute;
    top: 12px;
    right: 20px;
    color: var(--systemWhite);
    font-size: 2rem;
    width: 20px;
    height: 20px;
  }
  &__link {
    text-decoration: underline;
  }
}

.internetError {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 44px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  color: white;
  background: var(--primaryColor);
  transition: 0.5s;

  & i {
    position: absolute;
    top: 12px;
    right: 20px;
    color: var(--systemWhite);
    font-size: 2rem;
    width: 20px;
    height: 20px;
  }
}

.callTaxiError {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 44px;
  top: 0;
  display: flex;
  transform: translateY(-100%);
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  color: white;
  background: #f05151;
  transition: 0.5s;

  & i {
    background: white;
    border-radius: 50%;
    margin-right: 5px;
    color: #ff3b30;
  }
}
.callTaxiError.active {
  transform: translateY(0);
}

.rating {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.4rem;
  color: var(--mainTextColor);
  text-align: left;
  max-height: 40vh;
  overflow: scroll;

  & > div:nth-last-child(1) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  & > div:nth-child(1) {
    margin-top: 0;
  }

  & > div {
    margin: 10px 0;
  }

  & > span {
    font-size: 2rem;
    color: var(--primaryColor);

    & > span:nth-child(1) {
      margin: 0 18px 0 17px;
    }

    & > span:nth-child(2) {
      margin: 0 18px 0 0;
    }

    & > span:nth-child(3) {
      margin: 0 18px 0 0;
    }

    & > span:nth-child(4) {
      margin: 0 18px 0 0;
    }
  }

  & > span:nth-last-child(1) {
    margin-bottom: 10px;
  }

  & textarea {
    margin-top: 8px;
    min-height: 80px;
    width: 100%;
    text-decoration: none;
    resize: none;
    padding: 10px 12px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
  }

  & button {
    width: 190px;
    height: 40px;
    background: var(--primaryColor);
    border-radius: 4px;
    align-self: center;
    margin-bottom: 40px;
    color: var(--systemWhite);
    font-size: 1.6rem;
    border: none;
  }
}

.thanksModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--mainTextColor);

  & > div:nth-child(2) {
    width: 120px;
    height: 120px;
  }

  & > div:nth-child(2)::before {
    content: '';
    background: linear-gradient(0deg, #94d36c 8px, transparent 0),
      linear-gradient(90deg, #a5e878 22px, transparent 0),
      linear-gradient(0deg, #a5e878 22px, transparent 0);
    width: 100px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-bottom-left-radius: 10px;
  }
}

.toast {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #111;
  opacity: 0;
  transition: 0s;
  font-size: 1.5rem;
  z-index: 1099;
  color: #efefef;
  min-width: 5rem;
  border-radius: 10px;

  &.active {
    padding: 10px;
    transition: opacity 0.5s;
    opacity: 0.8;
  }
}

@media (max-width: 330px) {
  .statusPage__btn {
    margin-bottom: 0.5rem;
  }
}
