.default-button {
  position: relative;
  overflow: hidden;
  height: 44px;
  background: var(--primaryColor);
  color: var(--systemWhite);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  border: none;
  & div:nth-child(1) {
    width: 0;

    & div {
      transform: translateX(-130%);
    }
  }

  & span {
    font-size: 1.5rem;
    text-align: center;
  }
}
